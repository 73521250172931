.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-photo-gallery--gallery{
  overflow-y: hidden;
  overflow-x: hidden;
}

.Container {
  transition: transform 0.225s;
  transition-timing-function: ease-in-out;
}

.logo{
  background-image: var(--asset-logo);
  background-repeat: no-repeat;
  background-position: center;
}

.fadeOut {
  opacity:0;
  transition: opacity 1s linear;
}
.fadeIn {
  opacity:1;
}

.Background{
  background-image: var(--asset-background_image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position:absolute;
  margin: 0 auto;  
  height: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.fullscreen {
  z-index: 100;
}

.nav-list {
  z-index: 1;
}


.carousel-container {
  width: 100%;
  height: calc(100vw * 0.8);
}

.overlay-text {
  color: white;
  background: rgb(0, 0, 0); /* fallback color */
  background: rgba(0, 0, 0, 0.4);
  padding: 4px;
  position:absolute;
  margin: 1 auto;  
  height: auto;
  right: 0px;
  bottom: 0px;
  left: 2px;
}

.bottom-right {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.favListItems::-webkit-scrollbar {
  display: none;
}

.shadow {
  text-shadow: 5px 5px 5px lime;
  box-shadow: '12px 12px 12px 12px rgba(0,0,0,1)';
  border-radius: 5px;
  background-color: rgba(0,0,0,0.2);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.all-page-container {
  height: 100%;
  max-height: 100vh;
  overflow: auto;
}

.screensaver {
  position: relative;
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
